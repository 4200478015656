// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement, Component, StrictMode, Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import apolloClient from '../apolloClient';
import { ApolloProvider } from '@apollo/client';
import { LocaleProvider, Toaster } from '@plesk/ui-library';
import { Locale, Observer, Task } from 'jsw';
import Cookie from 'common/cookie';
import Routes from './Routes';
import PhpPage from './PhpPage';
import { useToaster } from '../../helpers/toasts';

import TASK_PROGRESS_BAR_QUERY from 'queries/TaskProgressBar.graphql';

const addPrefixToKeys = (obj, prefix) => Object.keys(obj).reduce((res, key) => {
    res[`${prefix}.${key}`] = obj[key];
    return res;
}, {});

const CommonToaster = () => {
    const { toasts, removeToast } = useToaster();
    return (<Toaster toasts={toasts} onToastClose={key => removeToast({ key })} data-type="toaster" />);
};

class App extends Component {
    state = {
        widgets: [],
        sections: Object.keys(Locale.sections).reduce((sections, name) => ({
            ...sections,
            ...addPrefixToKeys(Locale.sections[name].messages, name),
        }), {}),
    };

    componentDidMount() {
        Observer.append(({ name, messages }) => {
            this.setState(({ sections }) => ({
                sections: {
                    ...sections,
                    ...addPrefixToKeys(messages, name),
                },
            }));
        }, 'plesk:addedLocaleSection');

        apolloClient.query({
            query: TASK_PROGRESS_BAR_QUERY,
        }).then(({ data: { viewer, config } }) => {
            if (viewer.type === 'UNDEFINED') {
                return;
            }
            new Task.ProgressBar({
                wsEnabled: config.webSocket.enabled,
            });
        });
    }

    getConfirmation = (message, callback) => {
        try {
            const { callback: callbackReturnValue } = JSON.parse(message);

            if (typeof callbackReturnValue !== 'undefined') {
                callback(callbackReturnValue);
            } else {
                throw new Error();
            }
        } catch (e) {
            // eslint-disable-next-line no-alert
            callback(window.confirm(message));
        }
    };

    addWidget(widget) {
        this.setState(({ widgets }) => ({
            widgets: [
                ...widgets,
                widget,
            ],
        }));
    }

    render() {
        return (
            <StrictMode>
                <ApolloProvider client={apolloClient}>
                    <BrowserRouter getUserConfirmation={this.getConfirmation}>
                        <LocaleProvider messages={this.state.sections}>
                            <Routes>
                                <PhpPage />
                            </Routes>
                            {this.state.widgets}
                            <CommonToaster />
                        </LocaleProvider>
                    </BrowserRouter>
                </ApolloProvider>
                {'true' === Cookie.get('debug-testing-mode') ? (
                    <Fragment>
                        <style type="text/css">
                            {`
                                *, *:before, *:after {
                                    transitions-delay: 0s !important;
                                    transition-duration: 0s !important;
                                    animation-duration: 0s !important;
                                }
                            `}
                        </style>
                        <div id="bottomAnchor" style={{ position: 'fixed', top: 0, zIndex: -1, opacity: 0.001 }} />
                    </Fragment>
                ) : null}
            </StrictMode>
        );
    }
}

export default App;
