// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement } from 'react';
import classNames from 'classnames';
import { createLocation } from 'history';
import PropTypes from 'prop-types';
import { Tooltip } from '@plesk/ui-library';
import { prepareUrl } from 'jsw';
import { useLocalStorage } from 'common/hooks';
import ViewSwitcher from './ViewSwitcher';
import Brand from '../Brand';
import Link, { isMatch } from '../Link';
import { useLocation } from 'react-router-dom';

const Node = ({ id, styleClass, active, name, conhelp, link, target, custom, icon, hidden, onClick }) => {
    const location = useLocation();
    if (link) {
        link = prepareUrl(link);
        if (isMatch(link)) {
            link = createLocation(link, { reload: true }, null, location);
        }
    }

    return (
        <Tooltip title={conhelp}>
            <li
                id={id || null}
                className={classNames(!id && styleClass, active && 'active')}
            >
                <Link to={link} target={target} onClick={onClick} tabIndex={hidden ? -1 : null}>
                    <i
                        className={styleClass ? `icon-${styleClass.split(' ')[0]}` : null}
                        style={custom ? { backgroundImage: `url('${icon}')` } : null}
                    />
                    <span className="title">{name}</span>
                </Link>
            </li>
        </Tooltip>
    );
};

Node.propTypes = {
    id: PropTypes.string,
    styleClass: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string.isRequired,
    conhelp: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    target: PropTypes.string,
    custom: PropTypes.bool,
    icon: PropTypes.string,
    hidden: PropTypes.bool,
    onClick: PropTypes.func,
};

Node.defaultProps = {
    id: undefined,
    styleClass: undefined,
    active: false,
    target: undefined,
    custom: false,
    icon: undefined,
    hidden: false,
    onClick: undefined,
};

const Group = ({ id, showTitle, name, nodes, isSidebarClosed, onNodeClick }) => {
    const group = id.toString().replace('_', '-');
    const [isHidden, setHidden] = useLocalStorage(`menu-group-${group}-hidden`);

    let title = (
        <button
            className="menu-group-title"
            aria-expanded={isHidden === 'true' ? 'false' : 'true'}
            aria-controls={`menuId-${group}`}
            onClick={() => setHidden(isHidden === 'true' ? 'false' : 'true')}
            type="button"
        >
            <span className="menu-group-toggle" />
            <span className="menu-group-name">{name}</span>
        </button>
    );

    if (isSidebarClosed && name) {
        title = (
            <Tooltip title={name}>
                {title}
            </Tooltip>
        );
    }

    return (
        <li key={id} className={`menu-group-${group}`}>
            {showTitle ? title : null}
            {nodes && Object.keys(nodes).length ? (
                <ul className="sub-menu" id={`menuId-${group}`}>
                    {Object.keys(nodes).map(key => {
                        const { conhelp, ...props } = nodes[key];
                        return (
                            <Node
                                key={key}
                                conhelp={isSidebarClosed ? props.name : conhelp}
                                onClick={onNodeClick}
                                hidden={isHidden === 'true'}
                                {...props}
                            />
                        );
                    })}
                </ul>
            ) : null}
        </li>
    );
};

Group.propTypes = {
    id: PropTypes.string.isRequired,
    showTitle: PropTypes.bool.isRequired,
    name: PropTypes.string,
    nodes: PropTypes.object.isRequired,
    isSidebarClosed: PropTypes.bool.isRequired,
    onNodeClick: PropTypes.func,
};

Group.defaultProps = {
    onNodeClick: null,
    name: null,
};

const PageSidebar = ({ title, logo, navigation, viewSwitcher, isClosed, onNodeClick }) => (
    <div
        className={classNames(
            'page-sidebar',
            isClosed && 'page-sidebar--folded'
        )}
        data-type="page-sidebar"
    >
        <div className="page-sidebar__content">
            {logo ? (
                <div className="page-sidebar-brand">
                    {isClosed ? (
                        <div className="brand-collapsed">{title[0] || 'P'}</div>
                    ) : (
                        <Brand {...logo} />
                    )}
                </div>
            ) : null}

            <div className="page-sidebar-menu-wrapper">
                <ul className="page-sidebar-menu">
                    {navigation.map(({ id, ...props }) => (
                        <Group
                            key={id}
                            id={id}
                            isSidebarClosed={isClosed}
                            onNodeClick={onNodeClick}
                            {...props}
                        />
                    ))}
                </ul>
            </div>
        </div>
        {viewSwitcher ? (
            <div className="page-sidebar-footer-wrapper">
                <div className="page-sidebar-footer">
                    {<ViewSwitcher {...viewSwitcher} />}
                </div>
            </div>
        ) : null}
    </div>
);

PageSidebar.propTypes = {
    title: PropTypes.string.isRequired,
    logo: PropTypes.object,
    navigation: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        showTitle: PropTypes.bool,
        name: PropTypes.string,
        nodes: PropTypes.object,
    })).isRequired,
    viewSwitcher: PropTypes.shape({
        locale: PropTypes.object,
    }),
    isClosed: PropTypes.bool,
    onNodeClick: PropTypes.func,
};

PageSidebar.defaultProps = {
    logo: undefined,
    viewSwitcher: undefined,
    isClosed: false,
    onNodeClick: undefined,
};

export default PageSidebar;
