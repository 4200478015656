// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

export const toFormData = values => {
    if (values instanceof FormData) {
        return values;
    }
    const consume = (object, namespace) => {
        Object.keys(object).forEach(k => {
            const name = namespace ? `${namespace}[${k}]` : k;
            const value = object[k];
            switch (true) {
                case value && typeof value === 'object':
                    consume(value, name);
                    break;

                case value == null:
                    data.append(name, '');
                    break;

                default:
                    data.append(name, value);
                    break;
            }
        });
    };
    const data = new FormData();
    consume(values);
    return data;
};
