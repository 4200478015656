// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { ApolloClient, InMemoryCache, defaultDataIdFromObject, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

const errorLink = onError(error => {
    if (error.networkError?.statusCode === 400) {
        window.location.reload();
    }
});

const headers = {
    'X-Requested-With': 'XMLHttpRequest',
};

const tokenEl = document.getElementById('forgery_protection_token');
if (tokenEl) {
    headers['X-Forgery-Protection-Token'] = tokenEl.content;
}

const uploadLink = createUploadLink({
    uri: '/cp/graphql',
    headers,
});

const apolloClient = new ApolloClient({
    link: from([errorLink, uploadLink]),
    cache: new InMemoryCache({
        dataIdFromObject: object => {
            switch (object.__typename) {
                case 'ExtensionTab':
                case 'SiteButton':
                    return `${object.__typename}:${object.siteId}:${object.id}`;
                case 'ConfigSectionGdprCookie':
                    return `${object.__typename}:${object.type}:${object.name}`;
                default:
                    if (object.__typename?.startsWith('Config')
                        || ['User', 'Mode'].includes(object.__typename)
                    ) {
                        return object.__typename;
                    }
                    return defaultDataIdFromObject(object);
            }
        },
    }),
});

export default apolloClient;
